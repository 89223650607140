import React from 'react';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar, Link } from '@mui/material';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';
import step1 from "../assets/images/step1.svg";
import step2 from "../assets/images/step2.svg";
import step3 from "../assets/images/step3.svg";

function Stepssection() {
    return (
        <>
            <div className="wrapper">
                <Grid container textAlign="start" sx={{ py: 5 }}>
                    <Grid item xs={12} md={6} sx={{ mt: 4 }} >
                        <Typography className='steps-head'>
                            Get Started in just<br></br>
                            3 simple steps.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className='steps-div' sx={{ mb: 2, mt: 5 }} >
                        <h3 className='mb-3'>1. Enter Email</h3>
                        <div style={{ textAlign: "center" }}>
                            <img
                                src={step1}
                                alt="step1"
                                className='w-70'
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6} className='steps-div' sx={{ mb: 2, mt: 2 }}>
                        <h3 className='mb-3'>2. Verify with OTP</h3>
                        <div style={{ textAlign: "center" }}>
                            <img
                                className='w-70'
                                src={step2}
                                alt="step2"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6} className='steps-div' sx={{ mb: 5, mt: 2 }}>
                        <h3 className='mb-3'>3. Start Your SIP</h3>
                        <div style={{ textAlign: "center" }}>
                            <img
                                className='w-100'
                                src={step3}
                                alt="step3"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Stepssection;
