import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Link,
  Box,
  TextField,
} from "@mui/material";
import Header from "../pages/home/Appbar";
import MailIcon from "@mui/icons-material/Mail";
import Hero from "../assets/images/abouthero.svg";
import Who1 from "../assets/images/who1.svg";
import Who2 from "../assets/images/who2.svg";
import Who3 from "../assets/images/who3.svg";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import WebFooter from "../components/Web-footer";
import GetStart from "../components/getstart-card-contact";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { sendEnquiryEmail } from "../api";
import { toast } from "react-toastify";

function ContactUs() {
  // Define validation schema
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    message: Yup.string().required("Message is required"),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(sendEnquiryEmail, values);
      if (response.data.success) {
        toast.success("Enquiry email sent successfully!");
        resetForm();
      } else {
        toast.error(
          "There was an error sending your message. Please try again later."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "There was an error sending your message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Header />

      <Grid
        container
        alignItems="start"
        sx={{ pt: [0, 0, 8] }}
        className="heroSectionContainer"
      >
        <Grid item xs={12} md={6} sx={{ mt: 5 }}>
          <Typography variant="h1" className="heroText mb-3" sx={{ mb: 3 }}>
            We love to hear
            <br /> your <span>thoughts</span>
          </Typography>
          <Typography
            variant="body1"
            className="heroSubText mb-4"
            sx={{ mb: 4 }}
          >
            We are here to empower people, guide them to
            <br /> make intelligent investments and achieve their financial
            goals.
          </Typography>
          <Typography className="contact-mail">
            <MailIcon sx={{ fontSize: "30px", mr: 1 }} />
            <span> Our Email </span>
          </Typography>

          {/* <Typography sx={{ mt: 2 }}>admin@misshikacapital.com</Typography> */}
          <Typography sx={{ mt: 2 }}>
            <a
              href="mailto:service@misshikacapital.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              service@misshikacapital.com
            </a>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ mb: 6, mt: 5 }}
          display="flex"
          // justifyContent="end"
          justifyContent={{ xs: "start", md: "end" }}
        >
          <Box className="contact-bg">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <label className="contact-form-label">
                        First Name<span className="red-color">*</span>
                      </label>
                      <Field
                        name="firstName"
                        as={TextField}
                        fullWidth
                        required
                        placeholder="Enter First Name"
                        variant="outlined"
                        sx={{ backgroundColor: "white", mt: 2 }}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error red-color"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className="contact-form-label">
                        Last Name<span className="red-color">*</span>
                      </label>
                      <Field
                        name="lastName"
                        as={TextField}
                        fullWidth
                        required
                        placeholder="Enter Last Name"
                        variant="outlined"
                        sx={{ backgroundColor: "white", mt: 2, mb: 2 }}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error red-color"
                      />
                    </Grid>
                  </Grid>
                  <label className="contact-form-label">
                    Mobile Number<span className="red-color">*</span>
                  </label>
                  <Field
                    name="phone"
                    as={TextField}
                    fullWidth
                    required
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    sx={{ backgroundColor: "white", mt: 2, mb: 2 }}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error red-color"
                  />

                  <label className="contact-form-label">
                    Email<span className="red-color">*</span>
                  </label>
                  <Field
                    name="email"
                    as={TextField}
                    fullWidth
                    required
                    placeholder="Enter Email"
                    variant="outlined"
                    type="email"
                    sx={{ backgroundColor: "white", mt: 2, mb: 2 }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error red-color"
                  />

                  <label className="contact-form-label">
                    Message<span className="red-color">*</span>
                  </label>
                  <Field
                    name="message"
                    as={TextField}
                    fullWidth
                    required
                    placeholder="Enter your Message"
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={{ backgroundColor: "white", mt: 2, mb: 2 }}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error red-color"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit-btn"
                    sx={{ mt: 2, py: 1 }}

                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>

      <GetStart />
      <WebFooter />
    </>
  );
}

export default ContactUs;
