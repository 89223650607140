import React, { useEffect, useRef, useState } from 'react';

import "../assets/css/footer.css";
import logo from "../assets/images/footer-logo.svg";
import facebook from "../assets/images/facebook-icon.svg";
import insta from "../assets/images/instagram-icon.svg";
// import twitter from "../assets/images/twitter-icon.svg";
import linkedin from "../assets/images/linkedin-logo.svg";
import email from "../assets/images/email.png";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Footer = () => {

  const emailRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    // Check if the element is overflowing
    const emailElement = emailRef.current;
    if (emailElement.scrollWidth > emailElement.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, []);

  return (
    <div id="footer">
      <div className="wrapper">
        <Box className="first-Col space-sm">

          <Card className="footer-card">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo} alt="footer-logo" />
              </Link>
            </div>

            <Box className="img-container">
              <div className="Col">
                <div className="list-container2 list-container1">
                  <div className="footer-contact-content list-container">
                    {/* <img
                      className="social-icons-footer"
                      src={email}
                      alt="email"
                    /> */}

                    <div className="social-icons-footer">
                      <MailOutlineIcon />
                    </div>
                    <a
                      href="mailto:service@misshikacapital.com"
                      target="_balcnk"
                      style={{ textDecoration: "none" }}
                      className="footer-link-light footer-email-link"
                      ref={emailRef}
                      title={isTruncated ? "service@misshikacapital.com" : ""}  // Show title only if truncated

                    >
                      service@misshikacapital.com
                    </a>
                  </div>

                  {/* <div className="social-icons">
                    <div className="social-icon-single">
                      <img
                        className="icons-footer"
                        src={facebook}
                        alt="email"
                      />
                    </div>

                    <div className="social-icon-single">
                      <a target="_blank" className="footer-link-light">
                        <img
                          className="icons-footer"
                          src={insta}
                          alt="instagram"
                        />
                      </a>
                    </div>

                    <div className="social-icon-single">
                      <img
                        className="icons-footer"
                        src={linkedin}
                        alt="email"
                      />
                    </div>
                  </div> */}

                  {/* ----------------------------- */}

                  <div className="social-icons1">

                    <Box>
                      <Stack direction="row" spacing={2}>
                        <div className="mui-icon-container facebook">
                          <FacebookSharpIcon className="icon-size" />
                        </div>
                        <div className="mui-icon-container linkedin">
                          <LinkedInIcon className="icon-size" />
                        </div>
                        <div className="mui-icon-container instagram">
                          <InstagramIcon className="icon-size" />
                        </div>
                      </Stack>
                    </Box>

                  </div>


                </div>
              </div>
            </Box>
          </Card>

          <Card className="footer-card">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Company</a>
                <a href="/about-us" className="footer-link-light">
                  About Us
                </a>
                <a href="/contact" className="footer-link-light">
                  Contact us
                </a>

                {/* <Link
                  to="/coming-soon"
                  state={{
                    serviceType: "other",
                  }}
                  className="footer-link-light"
                >
                  Privacy Policy
                </Link>
                <a href="/coming-soon" className="footer-link-light">
                  Terms & Conditions
                </a> */}

              </div>
            </Box>
          </Card>

          <Card className="footer-card">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Mutual Funds</a>
                <a href="/coming-soon" className="footer-link-light">
                  Explore Funds
                </a>
                <a href="/coming-soon" className="footer-link-light">
                  High Return Funds
                </a>
                <Link
                  to="/coming-soon"
                  state={{
                    serviceType: "other",
                  }}
                  className="footer-link-light"
                >
                  SIP with 500
                </Link>

                <a href="/coming-soon" className="footer-link-light">
                  Tax Saving
                </a>

                <a href="/coming-soon" className="footer-link-light">
                  Large Cap
                </a>

                <a href="/coming-soon" className="footer-link-light">
                  Mid Cap
                </a>
                <a href="/coming-soon" className="footer-link-light">
                  Small Cap
                </a>
              </div>
            </Box>
          </Card>

          <Card className="footer-card quick-link-custom">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Quick Links</a>

                <a href="/coming-soon" className="footer-link-light">
                  Privacy Policy
                </a>

                <a href="/coming-soon" className="footer-link-light">
                  Terms & Conditions
                </a>
              </div>
            </Box>
          </Card>
        </Box>

        <Divider />

        {/* --------------- */}

        <div className="footer-bottom">
          <div className="botton-container">
            <p className="copyright">
              © 2024 Misshika Capital, All rights reserved.
              <span>
                {" "}
                Site by{" "}
                <a
                  className="sideby-links"
                  href="https://www.codeplateau.com/"
                  target="_blank"
                  rel="noopener noreferrer"

                >
                  {" "}
                  Codeplateau{" "}
                </a>{" "}
              </span>
            </p>

            <section className="popular-section">
              <p className="section-title">MOST POPULAR ON MISSHIKA CAPITAL</p>
              <p className="subsection-title">POPULAR MUTUAL FUNDS:</p>
              <div className="link-list">
                <a href="/coming-soon">QUANT SMALL CAP FUND | </a>
                <a href="/coming-soon">ICICI PRUDENTIAL COMMODITIES FUND | </a>
                <a href="/coming-soon">NIPPON INDIA SMALL CAP FUND | </a>
                <a href="/coming-soon">PARAG PARIKH FLEXI CAP FUND | </a>
                <a href="/coming-soon">
                  GROWW NIFTY TOTAL MARKET INDEX FUND |{" "}
                </a>
                <a href="/coming-soon">SBI SMALL MIDCAP FUND | </a>
                <a href="/coming-soon">TATA DIGITAL INDIA FUND | </a>
                <a href="/coming-soon">AXIS SMALL CAP FUND | </a>
                <a href="/coming-soon">ICICI PRUDENTIAL TECHNOLOGY FUND | </a>
                <a href="/coming-soon">HDFC INDEX FUND SENSEX PLAN | </a>
                <a href="/coming-soon">HDFC SMALL CAP FUND | </a>
                <a href="/coming-soon">AXIS EQUITY FUND | </a>
                <a href="/coming-soon">CANARA ROBECO SMALL CAP FUND | </a>
                <a href="/coming-soon">TATA SMALL CAP FUND | </a>
                <a href="/coming-soon">UTI NIFTY FUND</a>
              </div>
            </section>

            <section className="companies-section">
              <p className="subsection-title">MUTUAL FUNDS COMPANIES:</p>
              <div className="link-list">
                <a href="/coming-soon">SBI | </a>
                <a href="/coming-soon">AXIS | </a>
                <a href="/coming-soon">HDFC | </a>
                <a href="/coming-soon">UTI | </a>
                <a href="/coming-soon">NIPPON INDIA | </a>
                <a href="/coming-soon">ICICI PRUDENTIAL | </a>
                <a href="/coming-soon">TATA | </a>
                <a href="/coming-soon">KOTAK | </a>
                <a href="/coming-soon">DSP | </a>
                <a href="/coming-soon">CANARA ROBECO</a> |
                <a href="/coming-soon">SUNDARAM | </a>
                <a href="/coming-soon">MIRAE ASSET | </a>
                <a href="/coming-soon">IDFC | </a>
                <a href="/coming-soon">FRANKLIN TEMPLETON | </a>
                <a href="/coming-soon">PPFAS</a> |
                <a href="/coming-soon">MOTILAL OSWAL | </a>
                <a href="/coming-soon">INVESCO | </a>
                <a href="/coming-soon">EDELWEISS | </a>
                <a href="/coming-soon">ADITYA BIRLA SUN LIFE | </a>
                <a href="/coming-soon">LIC | </a>
                <a href="/coming-soon">HSBC | </a>
                <a href="/coming-soon">NAVI | </a>
                <a href="/coming-soon">QUANTUM | </a>
                <a href="/coming-soon">UNION | </a>
                <a href="/coming-soon">ITI | </a>
                <a href="/coming-soon">MAHINDRA MANULIFE | </a>
                <a href="/coming-soon">360 ONE | </a>
                <a href="/coming-soon">BOI | </a>
                <a href="/coming-soon">TAURUS | </a>
                <a href="/coming-soon">JM FINANCIAL | </a>
                <a href="/coming-soon">PGIM | </a>
                <a href="/coming-soon">SHRIRAM | </a>
                <a href="/coming-soon">BARODA BNP PARIBAS | </a>
                <a href="/coming-soon">QUANT</a> |
                <a href="/coming-soon">WHITEOAK CAPITAL | </a>
                <a href="/coming-soon">TRUST | </a>
                <a href="/coming-soon">SAMCO | </a>
                <a href="/coming-soon">NJ</a>
              </div>
            </section>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Footer;
