import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./components/coming-soon"; // Capitalize the component name
import Home from "./pages/home"; // Import your Home.js component
import AboutUs from "./pages/aboutus";
import ContactUs from "./pages/contactus.js";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <div className="App">
          <Routes>
            {/* Define the route for Home */}
            <Route path="/" element={<Home />} />
            <Route path="/coming-soon" element={<ComingSoon />} />{" "}
            {/* Capitalized component */}
            {/* Add more routes as needed */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            {/* You can add more routes here as needed */}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
