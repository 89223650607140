import React, { useState } from 'react';

import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar, Link } from '@mui/material';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';

import LoginPopup from '../pages/home/LoginPopup';


function StartSip() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid container alignItems="center" className="startsip" sx={{ mt: 4, mb: 4 }}>
                <Grid item xs={12} md={12}>
                    <Typography className="startsip-head" sx={{ mb: 4, mt: 4 }}>
                        Start SIPs at just ₹100.
                    </Typography>
                    <Button variant="contained"
                        //  className="getStartedBtn"
                        className="cta-button"
                        onClick={handleClickOpen}
                    >
                        Get Started
                    </Button>

                    <LoginPopup open={open} onClose={handleClose} />

                </Grid>

            </Grid>

        </>
    );
}

export default StartSip;
