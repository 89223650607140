import React from 'react';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar, Link } from '@mui/material';
import HeroSection from '../components/Hero';
import Handpicked from '../components/handpicked';
import StartSip from '../components/StartSip';
import Stepssection from '../components/Steps';
import TestimonialSection from '../components/testimonial';
import FAQ from '../components/FAQ';
import Header from './home/Appbar';
import InvestmentSection from '../components/Investmet';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';
import WebFooter from '../components/Web-footer';

import GetStart from '../components/getstart-card';

function Home() {
    return (
        <>
            <div>
                {/* Header */}
                <Header />
                {/* Hero Section */}
                <HeroSection />
                {/* second section*/}
                <Handpicked />
                {/* {start SIP section} */}
                <StartSip />
                {/* steps section */}
                <Stepssection />
                {/* testimonial section */}
                <TestimonialSection />
                {/* investment section */}
                <InvestmentSection />
                {/* FAQ */}
                <FAQ />

                <GetStart />

                <WebFooter />
            </div >
        </>
    );
}

export default Home;
